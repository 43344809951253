import './App.css';

function App() {
  const clicker = () => {
    window.open("https://drive.google.com/file/d/13UblwIknAxhFSX6bMU6RC5ynvM91kY1F/view?usp=sharing", '_blank')
  }
  return (
    <div className="App">
      <button 
      style={{backgroundColor:"green", color:"white", width: "100%", height: "10rem"}} 
      onClick={() => clicker()}>
        Click Here to fix common issues
      </button>
      <iframe src="https://forms.monday.com/forms/embed/7bfba991cc4e0747900d788d861dd14c?r=use1" width="100%" height="500" style={{border: "0"}}></iframe>
    </div>
  );
}

export default App;
